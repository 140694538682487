import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import useStepper from "../../../../../hooks/useStepper";
import { setForm } from "../../../../../reducers/formReducer";
import { confirmCheckboxValidationSchema } from "../../../../../schema";
import FormikCheckbox from "../../../../generic-components/formik-checkbox";
import FooterButtons from "../footer/FooterButtons";
import InvestmentChoices from "./InvestmentChoices";
import PropTypes from "prop-types";
import { confirmInvestmentAdvice } from "../../../../../graphql/mutations/confirmInvestmentAdvice";
import { startNingiJourneyForInvestment } from "../../../../../graphql/mutations/startNingiJourneyForInvestment";
import Dialog from "../../../../generic-components/Dialog";
import { deleteNingiAndOptions } from "../../../../../graphql/mutations/deleteNingiAndOptions";
import NINGI_JOURNEY_STATUS from "../../../../../constants/ningiJourneyStatus";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import SavePlan from "../../../../generic-components/save-plan";
import { updateUserNingiConsent } from "../../../../../graphql/mutations/updateUserNingiConsent";

const InvestmentChoicesStep1 = ({ form, formSharedTitle }) => {
  const {
    handleNext,
    setFormValues,
    formValues,
    setSavedToast,
    setIsLoadingNext,
    setIsLoadingSave,
    setSaveError,
    setErrorToast,
    btnClicked,
    activeStep,
    setInitialValues,
    setFullScreenLoading,
  } = useStepper();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [showIncompleteJourneyDialog, setIncompleteJourneyDialog] =
    useState(false);
  const [isConsentUpdating, setConsentUpdating] = useState(false);

  const [showConsentDialog, setConsentDialog] = useState(false);

  const initialValues = {
    confirmCheckbox: formValues.confirmCheckbox || false,
    showIncompleteJourneyDialog:
      !!form?.ningi_journey &&
      form?.ningi_journey?.journey_status ===
        NINGI_JOURNEY_STATUS.COMPLETED_NINGI_JOURNEY,
  };

  useEffect(() => {
    if (
      formValues.ningi_journey?.journey_status &&
      !formValues.incomplete_journey_consent
    ) {
      setConsentUpdating(false);
      setIncompleteJourneyDialog(true);
    }
  }, []);

  const [updateApplication] = useMutation(confirmInvestmentAdvice);
  const [updateUserConsent] = useMutation(updateUserNingiConsent);
  const [removeNingiDetails] = useMutation(deleteNingiAndOptions);
  const [startJourney] = useMutation(startNingiJourneyForInvestment);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const startNingiJourneyRedirect = async () => {
    setFullScreenLoading(true);
    setIsLoading(true);
    startJourney({
      variables: {
        redirect_url: `${window.location.origin}/redirect-application-form`,
        id: form.id,
      },
      onCompleted: (data) => {
        if (data.startNingiJourney?.journey_url) {
          window.location.href = data.startNingiJourney.journey_url;
        }
        setIncompleteJourneyDialog(false);
        setDialogLoading(false);
      },
      onError: (error) => {
        setFullScreenLoading(false);
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const onError = (error) => {
    setIsLoadingNext(false);
    setIsLoadingSave(false);
    setSaveError(error);
    setErrorToast(true);
  };

  const onCompleted = (data, isNingiDeleted) => {
    if (isNingiDeleted) {
      setInitialValues(data.update_temp_plans.returning[0]);
    } else {
      setFormValues((prevValues) => ({ ...prevValues, ningi_journey: null }));
      dispatch(setForm(data.update_temp_plans.returning[0]));
    }
    if (btnClicked === "save") {
      setSavedToast(true);
    }
    if (btnClicked === "save_and_next") {
      handleNext();
    }
    setIsLoadingNext(false);
    setIsLoadingSave(false);
  };

  const handleClick = (setFieldValue) => {
    setConsentUpdating(true);
    setFormValues({
      ...formValues,
      incomplete_journey_consent: true,
    });
    dispatch(
      setForm({
        incomplete_journey_consent: true,
      })
    );
    setFieldValue("showIncompleteJourneyDialog", false);

    updateUserConsent({
      variables: {
        id: form.id,
      },
      onCompleted: () => {
        setIncompleteJourneyDialog(false);
        setConsentUpdating(false);
      },
      onError: (error) => onError(error),
    });
  };

  return (
    <Formik
      validationSchema={confirmCheckboxValidationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        setFormValues((prevValues) => ({ ...prevValues, ...values }));
        if (btnClicked === "save_and_next") {
          setIsLoadingNext(true);
        } else if (btnClicked === "save") {
          setIsLoadingSave(true);
        }

        if (
          values.confirmCheckbox &&
          formValues.ningi_journey?.journey_status ===
            NINGI_JOURNEY_STATUS.COMPLETED_NINGI_JOURNEY &&
          !formValues.ningi_journey?.recommendation_changed
        ) {
          removeNingiDetails({
            variables: {
              temp_plan_id: form.id,
              ningi_journey_id: formValues.ningi_journey?.id,
              investment_advice: values.confirmCheckbox,
              step_number:
                btnClicked === "save" ? activeStep + 4 : activeStep + 5,
            },
            onCompleted: (data) => onCompleted(data, true),
            onError: (error) => onError(error),
          });
        } else {
          updateApplication({
            variables: {
              id: form.id,
              ningi_journey_id: values.confirmCheckbox
                ? formValues.ningi_journey?.id || 0
                : 0,
              investment_advice: values.confirmCheckbox,
              step_number:
                btnClicked === "save" ? activeStep + 4 : activeStep + 5,
            },
            onCompleted: (data) => onCompleted(data),
            onError: (error) => onError(error),
          });
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Box className="investment-choices">
            <Box className="application-page-container">
              <InvestmentChoices />
              <SavePlan />
              <Box className="freeadvice-container mt-30">
                <Typography className="content stepper-content">
                  Thanks to your employer, you have access to investment advice
                  at no extra cost to you. This advice is provided by our
                  partners, Westminster Wealth.
                </Typography>
                <Typography className=" mt-18 content stepper-content">
                  As an FCA (Financial Conduct Authority) regulated advice
                  service, this journey is detailed and thorough. Please allow
                  between 10-15 minutes to complete this.
                </Typography>
                <Typography className="mt-30 content stepper-content">
                  By clicking the button below, you agree to the personal data
                  you’ve already given to My Money Matters (when you registered
                  with us) now also being processed by Westminster Wealth to
                  provide you with this service.
                </Typography>

                <LoadingButton
                  buttonTitle="Click here to access investment advice"
                  trackingDetails={TRACKING_NAF_OPTIONS}
                  handleClick={startNingiJourneyRedirect}
                  loading={isLoading}
                  disabled={isLoading || values.confirmCheckbox}
                  styleClass="success-btn mt-18"
                />
              </Box>

              <Typography className="checkbox-info mt-30 stepper-content">
                If you don't need this advice that comes at no cost to you,
                please confirm below.
              </Typography>
              <FormikCheckbox
                name="confirmCheckbox"
                label={`I am confident on what fund(s) to invest in or I am happy to select the default investment option (if applicable) and do not wish to use the investment advice service offered to me by my employer.
                `}
                trackingDetails={TRACKING_NAF_OPTIONS}
                customHandleChange={() => setConsentDialog(true)}
              />
            </Box>

            <Dialog
              title={`You have come back to your My Money Matters ${formSharedTitle} application with an incomplete Investment Advice journey`}
              description={
                "Click the button below to continue your investment advice journey, or, close this pop up and acknowledge you don’t need advice by ticking the box."
              }
              saveButtonText={"Continue Investment Advice Journey"}
              closeButtonText={"Close Investment Advice Journey"}
              saveBtnClass="mod-btn"
              cancelBtnClass="mod-btn"
              showCloseLoading={isConsentUpdating}
              showLoading={isDialogLoading}
              showDialog={showIncompleteJourneyDialog}
              trackingDetails={TRACKING_NAF_OPTIONS}
              handleSave={() => {
                setDialogLoading(true);
                startNingiJourneyRedirect();
              }}
              handleClose={() => handleClick(setFieldValue)}
            />

            <Dialog
              title={
                "You have ticked the box confirming you don't need to use the investment advice service."
              }
              description={`
                Click the 'I want investment advice' button below to confirm that you do want investment advice, and we'll remove the tick in the previous box for you.

                Please note, by clicking the investment advice button below, you are accepting that your data will be processed by Westminster Wealth to provide you with this service.
                `}
              classes={"consent-dialog"}
              saveButtonText={"I want investment advice"}
              disableEscapeKeyDown={true}
              closeButtonText={
                "Proceed without advice and continue application."
              }
              saveBtnClass="mod-btn"
              cancelBtnClass="mod-btn"
              showDialog={showConsentDialog}
              trackingDetails={TRACKING_NAF_OPTIONS}
              handleSave={() => {
                setConsentDialog(false);
                setFieldValue("confirmCheckbox", false);
              }}
              handleClose={(_, reason) => {
                if (reason === "backdropClick") return;
                setConsentDialog(false);
              }}
            />
            <FooterButtons />
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

InvestmentChoicesStep1.propTypes = {
  form: PropTypes.object,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(InvestmentChoicesStep1);
